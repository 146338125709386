import React, { useContext, useEffect, useState,useRef} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import LoaderCircle from 'components/LoaderCircle';
import { useLocation } from 'react-router-dom';
import { Header,MyNFTs} from './components';
import styled from '@emotion/styled'
import { BsFillGrid1X2Fill } from 'react-icons/bs';
import { Context } from 'hooks/WalletContext'
import { getMaticInUSD } from 'services/getMaticInUSD'
import TocIcon from '@mui/icons-material/Toc';
import { useTranslation } from 'react-i18next';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { getUser } from 'services/User/getUser';
import { MdCollections } from 'react-icons/md'; 
import CollectionCard from './components/CollectionCard';
import { useFetch } from 'hooks/useFetch';
import LoaderCollection from 'components/LoaderCollection';
import ErrorMessage from 'components/ErrorMessage';
import LoaderNFT from 'components/LoaderNFT';
import OfferHistory from 'components/OfferHistory';
import SideBar from './components/SideBar';
import RoleProfiles from './components/RoleProfiles';
import NFTs from './components/NFTs'
import PropTypes from 'prop-types'

const ActiveTag = styled(Box)`
    border-bottom: ${props => props.active == "true" ? '3px solid #000' : 'null'};
    font-weight: ${props => props.active == "true" ? 'bold' : 'null'};
    //background-color: ${props => props.active == "true" ? '#F344A1' : '#000'};
    ${props => props.styles}
`

const ChoseLoader = ({
    userNfts,
    loadingNFT,
    openFilters,
    userCollection,
    loaderCollection,
    myNFTs,
    loaderNFTsOwner,
    myCollections,
    loaderCollectionOwner,
    offers,
    loadingHistory
    }) =>{
    return (
        <React.Fragment>
               {userNfts && loadingNFT && <Box><LoaderNFT openFilters={openFilters} /></Box>}
               {userCollection && loaderCollection && <LoaderCollection />}
               {myNFTs && loaderNFTsOwner && <LoaderNFT />}
               {myCollections && loaderCollectionOwner && <LoaderCollection />}
               {offers && loadingHistory && <div>loading...</div>}
        </React.Fragment>
    )
}

ChoseLoader.propTypes = {
    openFilters: PropTypes.bool,
    userNfts: PropTypes.bool,
    loadingNFT: PropTypes.bool,
    userCollection: PropTypes.bool,
    loaderCollection: PropTypes.bool,
    myNFTs: PropTypes.bool,
    loaderNFTsOwner: PropTypes.bool,
    myCollections: PropTypes.bool,
    loaderCollectionOwner: PropTypes.bool,
    offers: PropTypes.bool,
    loadingHistory: PropTypes.bool
}

const ChoseError = ({
    userNfts,
    errorNFT,
    userCollection,
    errorCollection,
    myNFTs,
    errorNFTsOwner,
    myCollections,
    errorCollectionOwner,
    offers,
    errorHistory
    }) =>{
    return (
        <React.Fragment>
            {userNfts && errorNFT && <Box><ErrorMessage error={errorNFT.message} /></Box>}
            {userCollection && errorCollection && <ErrorMessage error={errorCollection.message} />}
            {myNFTs && errorNFTsOwner && <ErrorMessage error={errorNFTsOwner.message} />}
            {myCollections && errorCollectionOwner && <ErrorMessage error={errorCollectionOwner.message} />}
            {offers && errorHistory && <ErrorMessage error={errorHistory.message} />}
        </React.Fragment>
    )
}

ChoseError.propTypes = {
    userNfts: PropTypes.bool,
    errorNFT: PropTypes.any,
    userCollection: PropTypes.bool,
    errorCollection: PropTypes.any,
    myNFTs: PropTypes.bool,
    errorNFTsOwner: PropTypes.any,
    myCollections: PropTypes.bool,
    errorCollectionOwner: PropTypes.any,
    offers: PropTypes.bool,
    errorHistory: PropTypes.any
}

const MenuProfile = ({
    userNfts,
    handleShowNFTtComponent,
    userCollection,
    handleCollectionsComponent,
    myCollections,
    handleShowMyCollections,
    myNFTs,
    handleShowMyNFT,
    offers,
    handleShowOffersComponent,
    userActivity,
    handleShowActivityComponent,
    typeUser,
    roleProfile,
    handleRoleProfileTab
    }) =>{
    const {t} = useTranslation("translate");
    return (
        <Box
            sx={{
                width: '100vw',
                height: '100%',
                overflowX:'auto',
                display:'flex',
                justifyContent:{xs:'space-between',sm:'space-between',md:'flex-start'},
                alignItems:'center',
                marginTop:{xs:'15px',sm:'0px'},
                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '
            }}
        >
            <ActiveTag active={userNfts+''}  component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleShowNFTtComponent}>
                <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><BsFillGrid1X2Fill size={20} /><span style={{fontSize:'20px', marginLeft:'5px'}}>NFTs</span></Box>
            </ActiveTag>
            <ActiveTag active={userCollection+''} component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleCollectionsComponent}>
                <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><MdCollections size={25} /><span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.tab_collections")}</span></Box>
            </ActiveTag>
            {typeUser > 1 && 
            <ActiveTag active={myCollections+''} component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleShowMyCollections}>
                <Box sx={{minWidth:'210px',display:'flex',justifyContent:'space-between',alignItems: 'center'}}>
                    <MdCollections size={25} /><span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.tab_collections_created")}</span>
                </Box>
            </ActiveTag>}
            {typeUser > 0 && 
            <ActiveTag active={myNFTs+''} component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleShowMyNFT}>
                <Box  sx={{width:'160px',display:'flex',justifyContent:'center',alignItems: 'center'}}><BsFillGrid1X2Fill size={20} /><span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.tab_nfts_created")}</span></Box>
            </ActiveTag>}
            <ActiveTag active={offers+''} component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleShowOffersComponent}>
                <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><LocalOfferIcon /><span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.activity")}</span></Box>
            </ActiveTag>
            <ActiveTag active={userActivity+''} component='div' sx={{display:'none',margin:'auto 25px', cursor:'pointer'}} onClick={handleShowActivityComponent}>
                <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><TocIcon /> <span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.activity")}</span></Box>
            </ActiveTag>
            {typeUser > 0 && 
            <ActiveTag active={roleProfile+''}  component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleRoleProfileTab}>
                <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}>
                    <BsFillGrid1X2Fill size={20} />
                    <span style={{fontSize:'20px', marginLeft:'5px'}}>
                        {typeUser == 2 && t("profile.tab_menu.curators")}
                        {typeUser == 5 && t("profile.tab_menu.artist")}
                    </span>
                </Box>
            </ActiveTag>}
        </Box>
    )
}

MenuProfile.propTypes = {
    userNfts: PropTypes.bool,
    handleShowNFTtComponent: PropTypes.func,
    userCollection: PropTypes.bool,
    handleCollectionsComponent: PropTypes.func,
    myCollections: PropTypes.bool,
    handleShowMyCollections: PropTypes.func,
    myNFTs: PropTypes.bool,
    handleShowMyNFT: PropTypes.func,
    offers: PropTypes.bool,
    handleShowOffersComponent: PropTypes.func,
    userActivity: PropTypes.bool,
    handleShowActivityComponent: PropTypes.func,
    typeUser: PropTypes.number,
    roleProfile: PropTypes.bool,
    handleRoleProfileTab: PropTypes.func
}

const ChoseOption = ({user,dataHistory,offers,errorHistory,roleProfile,typeUser,address}) =>{
    return (
        <React.Fragment>
            {   user && dataHistory && offers && !errorHistory &&
                <>
                    <Container maxWidth='lg'>
                        <OfferHistory content={dataHistory} />
                    </Container>
                </>
            }
            {
                user && roleProfile && typeUser &&
                <Box sx={{mt:4}}>
                    <RoleProfiles role={typeUser} address={address} />
                </Box>
            }   
        </React.Fragment>
    )
}

ChoseOption.propTypes = {
    user: PropTypes.object,
    dataHistory: PropTypes.array,
    offers: PropTypes.bool,
    errorHistory: PropTypes.any,
    roleProfile: PropTypes.bool,
    typeUser: PropTypes.number,
    address: PropTypes.string
}

const Profile = () => {
    const {t} = useTranslation("translate");
    let listNFT = 100;
    const [rangeBottom,setRangeBottom] = useState(0)
    const [rangeTop,setRangeTop] = useState(0)

    const limitNFTRef = useRef(listNFT);
    const countNfts = useRef(listNFT);
    const pageNftRef = useRef(0);
    const [sliceNFT,setSliceNFT] = useState(10);
    const [newNFTs,setNewsNFTs] = useState([]);

    const limitMyNFTRef = useRef(listNFT);
    const countMyNFT = useRef(listNFT);
    const pageMyNFTRef = useRef(0);
    const [sliceMyNFT,setSliceMyNFT] = useState(10);
    const [newsMyNFT,setNewsMyNFT] = useState([]);

    const limitCollectionRef = useRef(listNFT);
    const countCollection = useRef(listNFT);
    const pageCollectionRef = useRef(0);
    const [sliceCollection,setSliceCollection] = useState(10);
    const [newsCollection,setNewsCollection] = useState([]);

    const limitMyCollectionRef = useRef(listNFT);
    const countMyCollection = useRef(listNFT);
    const pageMyCollectionRef = useRef(0);
    const [sliceMyCollection,setSliceMyCollection] = useState(10);
    const [newsMyCollection,setNewsMyCollection] = useState([]);

    const tabActive = useRef("nft");
    const {data} = useContext(Context)
    const [user, setUser] = useState(null);
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    let address = query.get("address");
    const [userNfts, setUserNfts] = useState(true);
    const [typeUser, setTypeUser] = useState(0);
    const [offers, setOffers] = useState(false);
    const [userActivity, setUserActivity] = useState(false);
    const [artists,setArtists] = useState(false);
    const [load,setLoad] = useState(false);
    const [firstLoad,setFirstLoad] = useState(true);
    const [firstRequest,setFirstRequest] = useState(true);
    const changeFilter = useRef(false)
    const [selectedFilter,setSelectedFilter] = useState('');
    const [msgProfile,setMsgProfile] = useState(false);
    const [myCollections,setMyCollections] = useState(false);
    const [myNFTs,setMyNFTs] = useState(false);
    const [userCollection, setUserCollection] = useState(false);
    const [openFilters, setOpenFilters] = useState(true);
    const [openModalForm, setOpenModalForm] = useState(false);
    const [roleProfile, setRoleProfile] = useState(false);
    const [typeFilter,setTypeFilter] = useState('')
    const [categoriesString, setCategoriesString] = useState('');

    const [urlNFT,setUrlNFT] = useState(`${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`);
    const [ownerNFTUrl, setOwnerNFTUrl] = useState(`${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`);
    const [userCollectionUrl, setUserCollectionUrl] = useState(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&owner=${address}&page=${pageCollectionRef.current}&limit=${limitCollectionRef.current}&order=created`);
    const [userCollectionOwnerUrl, setUserCollectionOwnerUrl] = useState(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&key_name=CREATOR&key_val=${address}&page=${pageMyCollectionRef.current}&limit=${limitMyCollectionRef.current}&order=created`);
    const [historyURL,setHistoryUrl] = useState(process.env.REACT_APP_URL_API+`/history?domain=${process.env.REACT_APP_DOMAIN}&wallet=${address}&blockchain=${process.env.REACT_APP_NETWORK_NAME}&page=0&limit=30&order=created`)

    let {data:dataNFT, loading:loadingNFT, error:errorNFT} = useFetch(urlNFT); // NFT
    let {data: NFTsOwner, loading: loaderNFTsOwner, error: errorNFTsOwner} = useFetch(ownerNFTUrl); // NFTCreated
    let {data: dataCollection, loading: loaderCollection, error: errorCollection} = useFetch(userCollectionUrl);  // Collection
    let {data: collectionsOwner, loading: loaderCollectionOwner, error: errorCollectionOwner} = useFetch(userCollectionOwnerUrl);// Collection owner
    let {data:dataHistory, error:errorHistory, loading:loadingHistory} = useFetch(historyURL)

    const [addressTemp,setAddressTemp] = useState(address)
    const [firstRequestInfo,setFirstRequestInfo] = useState(false);
    const [editProfile,setEditProfile] = useState(false);
    const [initEditProfile, setInitEditProfile] = useState(false);
    const [openPopText,setOpenPopText] = useState(false);
    const [myNFTRandom,setMyNFTRandom] = useState({});


    const [dataTemp,setDataTemp] = useState(
        {
            username:'',
            email:''
        }
    )
    const [formEditProfile,setFormEditProfile] = useState({
        avatar:'',
        fileAvatar: [],
        banner:'',
        fileBanner: [],
        username:'',
        email:'',
        country:'',
        social_media:{
            twitter:'',
            instagram:'',
        },
        validateAttr:{username:null,email:null,avatar:null,banner:null,instagram:null,twitter:null}
    });


    const handleConcatNfts = () =>{
        if(dataNFT && dataNFT.length > 0 && !loadingNFT){
            setNewsNFTs([...newNFTs,...dataNFT]);
        }
    }

    useEffect(()=>{
        handleConcatNfts();
    },[dataNFT,loadingNFT])

    const handleConcatMyNfts = () =>{
        if(NFTsOwner && NFTsOwner.length > 0 && !loaderNFTsOwner){
            setNewsMyNFT([...newsMyNFT,...NFTsOwner]);
        }
    }

    useEffect(()=>{
        handleConcatMyNfts();
    },[NFTsOwner,loaderNFTsOwner])

    const handleConcatCollection = () =>{
        if(dataCollection && dataCollection.length > 0 && !loaderCollection){
            setNewsCollection([...newsCollection,...dataCollection]);
        }
    }

    useEffect(()=>{
        handleConcatCollection();
    },[dataCollection,loaderCollection])

    const handleConcatMyCollection = () =>{
        if(collectionsOwner && collectionsOwner.length > 0 && !loaderCollectionOwner){
            setNewsMyCollection([...newsMyCollection,...collectionsOwner]);
        }
    }

    useEffect(()=>{
        handleConcatMyCollection();
    },[collectionsOwner,loaderCollectionOwner])


    
    const handleGetResultsWithFilter = ()=>{
        if(typeFilter === "priceRange"){
            setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=PRICE_RANGE_BOTTOM&key_val=${rangeBottom}&key_name=PRICE_RANGE_TOP&key_val=${rangeTop}&domain=${process.env.REACT_APP_DOMAIN}&owner=${address}`)
            return 0;
        }
        if(typeFilter === "availability"){
            setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=${selectedFilter}&key_val=1&owner=${address}`)
            return 0;
        }
        if(typeFilter === "type"){
            setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=${selectedFilter}&key_val=${selectedFilter}&owner=${address}`)
            return 0;
        }
        if(typeFilter === "category"){
            setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=CATEGORY&key_val=${categoriesString}&owner=${address}`)
            return 0;
        }
    }
 
    const handleGetWithOutFilter = ()=>{
        setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`)
    }

    const getNfts = () =>{
        if(!loadingNFT){
            if(sliceNFT < (countNfts.current - 20)){
                return 0;
            }
            countNfts.current = countNfts.current + limitNFTRef.current
            pageNftRef.current++;
            if(typeFilter != ''){
                handleGetResultsWithFilter();
                return 0;
            }
            handleGetWithOutFilter();
        }
    }

    const handleGetResultsWithFilterMyNFTs = ()=>{
        if(typeFilter === "priceRange"){
            setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created&key_name=PRICE_RANGE_BOTTOM&key_val=${rangeBottom}&key_name=PRICE_RANGE_TOP&key_val=${rangeTop}&domain=${process.env.REACT_APP_DOMAIN}&owner=${address}`)
            return 0;
        }
        if(typeFilter === "availability"){
            setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created&key_name=${selectedFilter}&key_val=1&owner=${address}`)
            return 0;
        }
        if(typeFilter === "type"){
            setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created&key_name=${selectedFilter}&key_val=${selectedFilter}&owner=${address}`)
            return 0;
        }
        if(typeFilter === "category"){
            setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created&key_name=CATEGORY&key_val=${categoriesString}&owner=${address}`)
            return 0;
        }
    }
 
    const handleGetWithOutFilterMyNFTs = ()=>{
        setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&wallet=${address}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`)
    }

    const getMyNfts = () =>{
        if(!loaderNFTsOwner){
            if(sliceMyNFT < (countMyNFT.current - 20)){
                return 0;
            }
            countMyNFT.current = countMyNFT.current + limitMyNFTRef.current
            pageMyNFTRef.current++;
            if(typeFilter != '' && selectedFilter != ''){
                handleGetResultsWithFilterMyNFTs();
                return 0;
            }
            handleGetWithOutFilterMyNFTs();
        }
    }


    const infinityScroll = () => {
        if(tabActive.current == "nft" && newNFTs && sliceNFT <= newNFTs.length){
            getNfts();
            setSliceNFT(sliceNFT+10);
            return 0;
        }
        if(tabActive.current == "myNFt" &&  newsMyNFT && sliceMyNFT <= newsMyNFT.length){
            getMyNfts();
            setSliceMyNFT(sliceMyNFT+10)
            return 0;
        }
        if(tabActive.current == "collection" && dataCollection && limitCollectionRef.current <= dataCollection.length && !loaderCollection){
            setSliceCollection(sliceCollection + 10)
            if((countCollection.current - 20) < sliceCollection){
                countCollection.current = countCollection.current + limitCollectionRef.current
                pageCollectionRef.current++;
                setUserCollectionUrl(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&owner=${address}&page=${pageCollectionRef.current}&limit=${limitCollectionRef.current}&order=created`)
            }
            return 0;
        }
        if(tabActive.current == "myCollection"  && collectionsOwner && limitMyCollectionRef.current <= collectionsOwner.length && !loaderCollectionOwner){
            setSliceMyCollection(sliceMyCollection + 10)
            if((countMyCollection.current - 20) < sliceMyCollection){
                countMyCollection.current = countMyCollection.current + limitMyCollectionRef.current
                pageMyCollectionRef.current++;
                setUserCollectionOwnerUrl(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&owner=${address}&page=${pageMyCollectionRef.current}&limit=${limitMyCollectionRef.current}&creator=${address}`)
            }
            return 0;
        }
    }

    const handleActiveEditProfile = ()=>{
        if(!initEditProfile){
            setEditProfile(!editProfile);
            if(editProfile){
                setArtists(true)
                setUserNfts(false)
                setUserActivity(false)
                setOffers(false)
                setUserCollection(false)
                setMyCollections(false)
                setMyNFTs(false)
                setRoleProfile(false)
            }else{
                setUserNfts(false)
                setUserActivity(false)
                setOffers(false)
                setUserCollection(false)
                setMyCollections(false)
                setMyNFTs(false)
                setArtists(true)
                setRoleProfile(false)
            }
        }
    }
   
    const handleShowNFTtComponent = () => {
        if(!initEditProfile){
            tabActive.current = "nft"
            setUserNfts(true)
            setUserActivity(false)
            setArtists(false)
            setOffers(false)
            setEditProfile(false)
            setUserCollection(false)
            setMyCollections(false)
            setMyNFTs(false)
            setRoleProfile(false)
        }
    }

    const handleCollectionsComponent = () => {
        if(!initEditProfile){
            tabActive.current = "collection"
            setUserNfts(false)
            setUserActivity(false)
            setArtists(false)
            setOffers(false)
            setEditProfile(false)
            setUserCollection(true)
            setMyCollections(false)
            setMyNFTs(false)
            setRoleProfile(false)
        }
    }

    const handleShowActivityComponent = ()=>{
        if(!initEditProfile){
            setUserNfts(false)
            setUserActivity(true)
            setArtists(false)
            setOffers(false)
            setEditProfile(false)
            setUserCollection(false)
            setMyCollections(false)
            setMyNFTs(false)
            setRoleProfile(false)
        }
    }


    const handleShowOffersComponent = ()=>{
        if(!initEditProfile){
            setUserNfts(false)
            setUserActivity(false)
            setArtists(false)
            setOffers(true)
            setEditProfile(false);
            setUserCollection(false)
            setMyCollections(false)
            setMyNFTs(false)
            setRoleProfile(false)
        }
    }
    const handleShowMyCollections = ()=>{
        if(!initEditProfile){
            tabActive.current = "myCollection"
            setUserNfts(false)
            setUserActivity(false)
            setArtists(false)
            setOffers(false)
            setEditProfile(false);
            setUserCollection(false)
            setMyCollections(true)
            setMyNFTs(false)
            setRoleProfile(false)
        }
    }
    const handleShowMyNFT = ()=>{
        if(!initEditProfile){
            tabActive.current = "myNFt"
            setUserNfts(false)
            setUserActivity(false)
            setArtists(false)
            setOffers(false)
            setEditProfile(false);
            setUserCollection(false)
            setMyCollections(false)
            setMyNFTs(true)
            setRoleProfile(false)
        }
    }



    const handleRoleProfileTab = () =>{
        setUserNfts(false)
        setUserActivity(false)
        setArtists(false)
        setOffers(false)
        setEditProfile(false);
        setUserCollection(false)
        setMyCollections(false)
        setMyNFTs(false)
        setRoleProfile(true)
    }

    let timeOut = null;
    const getMyDataProfile = async ()=>{
        clearTimeout(timeOut);
        setMsgProfile('');
        setLoad(true);
        setOpenModalForm(false);
        let userData = null;
        userData = await getUser(address).finally(()=>{setLoad(false);setMsgProfile('')}).catch(err=>{console.log("error profile",err);setMsgProfile(err+'');setLoad(false)})
        if(userData && userData.username && userData.registered){
            setUser(userData)
            setFormEditProfile({...formEditProfile,username:userData.username,country : userData.country,social_media:{twitter:userData.twitter,instagram:userData.facebook}})
            setDataTemp({...dataTemp,username:userData.username,email:userData.email})
            setLoad(false)
            setOpenModalForm(true);
        }else{
            clearTimeout(timeOut);
            if(!userData.registered){
                setOpenPopText(true);
            }
            setLoad(false)
        }
    }

    const getDataProfile = async ()=>{
        setFormEditProfile({...formEditProfile,username:'',avatar:'',fileAvatar:[],email:'',banner:'',fileBanner:[]});
        setDataTemp({...dataTemp,username:'', email :''});
        setUser(null)
        setLoad(true)
        let isOwner = false
        let wallet = localStorage.getItem('wallet')
        if (wallet) {
            isOwner =  (address.toUpperCase() == wallet.toUpperCase()) ? true : false
        }
        let userData = await getUser(address,isOwner).finally(()=>{setLoad(false);setFirstLoad(false);setMsgProfile('')}).catch(err=>{console.log("error profile",err);setMsgProfile(err+'');setLoad(false)})
        if(userData) {
            setUser(userData)
            if(userData != null  && userData.username){
                setFormEditProfile({...formEditProfile,username:userData.username, email : userData.email,country : userData.country,  description : userData.about, social_media : {
                    twitter : userData.twitter,
                    instagram : userData.facebook,
                }})
                if(userData.role === 5){
                    setTypeUser(5)
                }else if (userData.role === 2){
                    setTypeUser(2)
                } else {
                    setTypeUser(0)
                }
                setDataTemp({...dataTemp,username:userData.username, email : userData.email, description : userData.about, social_media : {
                    twitter : userData.twitter,
                    instagram : userData.facebook
                }})
            }
        }
    }


    useEffect(()=>{
        setTypeFilter('');
        setTypeUser(0);
        tabActive.current = "nft";
        handleShowNFTtComponent();
        setFormEditProfile(
            {
                avatar:'',
                fileAvatar: [],
                banner:'',
                fileBanner: [],
                username:'',
                email:'',
                country:'',
                social_media:{
                    twitter:'',
                    instagram:'',
                },
                validateAttr:{username:null,email:null,avatar:null,banner:null,instagram:null,twitter:null}
            }
        );

    
        try {
            if(address != null && address != 'undefined' && !firstRequestInfo || address != addressTemp){
                countNfts.current = limitNFTRef.current;
                pageNftRef.current = 0;
                setSliceNFT(3);
                setNewsNFTs([]);
        
                countMyNFT.current = limitMyNFTRef.current;
                pageMyNFTRef.current = 0;
                setSliceMyNFT(3);
                setNewsMyNFT([]);
        
                countCollection.current = limitCollectionRef.current;
                pageCollectionRef.current = 0;
                setSliceCollection(4);
                setNewsCollection([]);

                setFirstLoad(true)
                setFirstRequestInfo(true)
                setAddressTemp(address)
                setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`)
                setUserCollectionUrl(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&owner=${address}&page=0&limit=${limitCollectionRef.current}&order=created`)
                setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`)
                setHistoryUrl(process.env.REACT_APP_URL_API+`/history?domain=${process.env.REACT_APP_DOMAIN}&wallet=${address}&blockchain=${process.env.REACT_APP_NETWORK_NAME}&page=0&limit=30&order=created`)
                setMyNFTRandom(null)
                getDataProfile(); 
            }
            getMaticInUSD().then((usd) => {
                localStorage.setItem('USD',""+usd)
            })
        } catch (error) {
            console.log(error)
        }
    },[address,addressTemp])

    useEffect(()=>{
        window.scrollTo(0,0);
        if(!address){
            window.location.href = '/'
        }

        setNewsNFTs([])
        setNewsMyNFT([])
        setNewsCollection([])
        setTypeFilter('')
        if(typeFilter == ''){
            if(!loadingNFT){
                setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitMyNFTRef.current}&page=${pageMyNFTRef.current}&order=created`)
            }
        }
    },[])

    useEffect(()=>{ // !important show cards when finish request
        setFirstRequest(false);
        if(!firstRequest){
            changeFilter.current = false;
        }
    },[changeFilter,dataNFT,loadingNFT,NFTsOwner,loaderNFTsOwner]);

    if(firstLoad ||(address == null || address == 'undefined')){
        return (
        <Box sx={{width:'100vw',height:'80vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
            {
                (address == null || address == 'undefined') ? 
                <ErrorMessage error={t("message_error.address_not_found")} />
                :
                <LoaderCircle text={t("profile.loading_profile")} />
            }
        </Box>)
    }

    return (
        <Box component="header" sx={{position:'relative'}}>
            <Header 
                formEditProfile={formEditProfile} 
                setFormEditProfile={setFormEditProfile} 
                user={user} 
                address={address} 
                data={data}
                handleActiveEditProfile={handleActiveEditProfile}
                editProfile={editProfile}
                initEditProfile={initEditProfile}
                getMyDataProfile={getMyDataProfile}
                openModalForm={openModalForm}
                setOpenModalForm={setOpenModalForm}
                load={load}
                setInitEditProfile={setInitEditProfile}
                dataTemp={dataTemp}
                setDataTemp={setDataTemp}
                openPopText={openPopText}
                setOpenPopText={setOpenPopText}
                myNFTRandom={myNFTRandom}
            />
            <Box
                component='section'
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    '@media screen and (max-width: 750px)': {
                        flexDirection: 'column',   
                    }
                }}
            >
                <MenuProfile 
                    userNfts={userNfts}
                    handleShowNFTtComponent={handleShowNFTtComponent}
                    userCollection={userCollection}
                    handleCollectionsComponent={handleCollectionsComponent}
                    myCollections={myCollections}
                    handleShowMyCollections={handleShowMyCollections}
                    myNFTs={myNFTs}
                    handleShowMyNFT={handleShowMyNFT}
                    offers={offers}
                    handleShowOffersComponent={handleShowOffersComponent}
                    userActivity={userActivity}
                    handleShowActivityComponent={handleShowActivityComponent}
                    typeUser={typeUser}
                    roleProfile={roleProfile}
                    handleRoleProfileTab={handleRoleProfileTab}
               />
            </Box>
            <Divider />
            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    position:'relative',
                    marginBottom:'1.5rem',
                    '@media screen and (max-width: 750px)': {
                        display:'grid',
                        gridTemplateColumns:'repeat(1, 1fr)'
                    }
                }}
            >
                {
                    !offers && !artists && !roleProfile &&
                    <SideBar 
                        wallet={address}
                        urlProject={((userNfts || myNFTs) && setOwnerNFTUrl) || ((userCollection || myCollections) && setUserCollectionUrl)}
                        isNFT={userNfts || myNFTs}
                        isCollection={userCollection || myCollections}
                        openFilters={openFilters} 
                        setOpenFilters={setOpenFilters}
                        urlNFT={urlNFT}
                        setUrlMyNFT={setUrlNFT}
                        typeFilter={typeFilter}
                        setTypeFilter={setTypeFilter}
                        loadingNFT={loadingNFT}
                        limitNFTRef={limitNFTRef}
                        listNFT={listNFT}
                        setNewsNFTs={setNewsNFTs}
                        setSliceNFT={setSliceNFT}
                        setNewsMyNFT={setNewsMyNFT}
                        setSliceMyNFT={setSliceMyNFT}
                        requestIsLoad={loadingNFT}
                        requestIsLoadOwner={loaderNFTsOwner}
                        countNfts={countNfts}
                        pageNftRef={pageNftRef}
                        countMyNFT={countMyNFT}
                        pageMyNFTRef={pageMyNFTRef}
                        isProfile={false}
                        rangeBottom={rangeBottom}
                        setRangeBottom={setRangeBottom}
                        rangeTop={rangeTop}
                        setRangeTop={setRangeTop}
                        changeFilter={changeFilter}
                        ownerNFTUrl={ownerNFTUrl}
                        setOwnerNFTUrl={setOwnerNFTUrl}
                        tabActive={tabActive}
                        setSelectedFilter={setSelectedFilter}
                        categoriesString={categoriesString}
                        setCategoriesString={setCategoriesString}
                    />
                }
                <Box
                    display='flex'
                    justifyContent={window.screen.width > 2100 ? "flex-start" : "center"}
                    alignItems='center'
                    sx={{width:'100%', padding: '0px 15px 0px 15px'}}
                >
                    <Container maxWidth={'xl'} sx={{mt:'15px'}}>
                    <Box 
                        sx={{
                            marginBottom:'30px', 
                            width:'100%',
                            margin: '0 auto'
                        }}
                    >
                        <>
                            {user && userNfts && !errorNFT && !firstRequest && !changeFilter.current &&  
                                <NFTs 
                                    content={newNFTs}
                                    setMyNFTRandom={setMyNFTRandom} 
                                    openFilters={openFilters} 
                                    infinityScroll={infinityScroll}
                                    firstRequest={firstRequest}
                                    limit={sliceNFT}
                                    loadingNFT={loadingNFT}
                                />  
                            }
                            {
                                user && userCollection && !errorCollection &&
                                <CollectionCard 
                                    content={newsCollection} 
                                    limit={sliceCollection} 
                                    infinityScroll={infinityScroll} 
                                    openFilters={openFilters} 
                                    loaderCollection={loaderCollection}
                                />
                            }
                            {
                                user && myNFTs && !errorNFTsOwner && !firstRequest && !changeFilter.current &&  
                                <MyNFTs 
                                    content={newsMyNFT}
                                    setMyNFTRandom={setMyNFTRandom} 
                                    openFilters={openFilters} 
                                    infinityScroll={infinityScroll}
                                    firstRequest={firstRequest}
                                    limit={sliceMyNFT}
                                    loadingNFT={loaderNFTsOwner}
                                />  
                            }
                            {
                                user && myCollections && !errorCollectionOwner &&
                                <CollectionCard 
                                    content={newsMyCollection} 
                                    limit={sliceMyCollection} 
                                    infinityScroll={infinityScroll} 
                                    openFilters={openFilters} 
                                    loaderCollection={loaderCollectionOwner}
                                />
                            }       
                                             
                            <ChoseOption 
                                user={user}
                                dataHistory={dataHistory}
                                offers={offers}
                                errorHistory={errorHistory}
                                roleProfile={roleProfile}
                                typeUser={typeUser}
                                address={address}
                            />
                            <ChoseError
                                userNfts={userNfts}
                                errorNFT={errorNFT}
                                userCollection={userCollection}
                                errorCollection={errorCollection}
                                myNFTs={myNFTs}
                                errorNFTsOwner={errorNFTsOwner}
                                myCollections={myCollections}
                                errorCollectionOwner={errorCollectionOwner}
                                offers={offers}
                                errorHistory={errorHistory}
                            />
                            <ChoseLoader 
                                openFilters={openFilters}
                                userNfts={userNfts}
                                loadingNFT={loadingNFT} 
                                userCollection={userCollection}
                                loaderCollection={loaderCollection}
                                myNFTs={myNFTs}
                                loaderNFTsOwner={loaderNFTsOwner}
                                offers={offers}
                                loadingHistory={loadingHistory}
                            />
                        </>
                        <center>
                            {
                                msgProfile+''
                            }
                        </center>
                    </Box>
                    </Container>
                </Box>
            </Box>
        </Box>
    );
}

export default Profile;

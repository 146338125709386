import React from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isMinted } from 'services/ERC721/isMinted';

const ViewCard = ({nft}) =>{
    const { t } = useTranslation("translate");
    let imgFull = document.querySelector('#imgFull');
    
    const handleViewFullscreen = (e,obj) => {
        if(e && e.target && obj && obj.metadata && String(obj.metadata.format).toUpperCase() != "MPEG"){ 
            if(!obj.metadata.is_video && obj.thumb_url_large){
                e.target.attributes.getNamedItem('src').value = obj.metadata.image_url; 
            }
            if(e.target.requestFullscreen) {
                e.target.requestFullscreen();
            } else if( e.target.mozRequestFullScreen) {
                e.target.mozRequestFullScreen();
            } else if( e.target.webkitRequestFullscreen) {
                e.target.webkitRequestFullscreen();
            } else if(e.target.msRequestFullscreen) {
                e.target.msRequestFullscreen();
            }
        }else{
            if(obj && obj.metadata && obj.metadata.image_url &&  String(obj.metadata.format).toUpperCase() == "MPEG" ){
                window.open(obj.metadata.image_url, '_blank');
            }
        }
    
    }
  

    const handleResetViewFullscreen = (e,obj) => {
        if(obj && !obj.metadata.is_video && obj.thumb_url_large){
            e.target.attributes.getNamedItem('src').value = obj.thumb_url_large
        }
    }      

    React.useEffect(() => {
        let count = 0;
        if(imgFull && nft){
            imgFull.onfullscreenchange = async(e) =>{
                count++;
                if(count == 2){
                    handleResetViewFullscreen(e,nft);
                    count=0;
                }
            } 
        }
    },[imgFull]);

    return(
        <React.Fragment>
            <CardActionArea
                sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    padding:'8px 8px'
                }}
            >
                {
                nft && nft.metadata.json_data.isVideo  ?
                <CardMedia
                    onClick={async(e)=>{handleViewFullscreen(e,nft)}}
                    className={'card-collection'}
                    component={'video'}
                    src={nft && nft.thumb_url}
                    autoPlay
                    loop
                    muted
                    sx={{
                        position:'relative',
                        borderRadius: '8px',
                        height:'100%',
                        width:'100%',
                        margin:'0 auto'
                    }}
                />
                :
                <CardMedia
                    onClick={async(e)=>{handleViewFullscreen(e,nft)}}
                    id="card-media"
                    ref={(img)=>{imgFull = img}}
                    component="img"
                    className={'card-collection'}
                    src={nft && nft.thumb_url}
                    alt="nft image"
                    sx={{
                        width: '100%',
                        borderRadius: '8px 8px 8px 8px',
                        "&:fullScreen":{
                            objectFit:'contain !important',
                            borderRadius: 'none !important',
                            width: '500px !important',
                            height: '500px !important',
                            overflow: 'hidden !important'
                        }
                    }}
                />
            }
            </CardActionArea>
            {
                nft && nft.metadata && !nft.metadata.is_video && nft.metadata.format == "mpeg" && nft.metadata.image_url && 
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Box component={"audio"} controls controlsList="nodownload"
                        src={nft.metadata.image_url}
                        typeof="audio/mpeg"
                        sx={{
                            "& audio":{
                                color:'red'
                            }
                        }} 
                        width="100%" height="32px"
                    />
                </Box>
            }
            <CardContent
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom:'0px',
                        boxSizing:'content-box', 
                        paddingBottom:'0px',                          
                        '@media screen and (max-width: 884px)': {
                            flexDirection: 'column',
                            alignItems: 'center'
                        },
                    
                    }}
                >   
                    <Typography gutterBottom variant="body2" component="h2" sx={{marginRight:'20px', paddingBottom:'0px'}}>
                        {t("nft-screen.format")}: {nft && nft.metadata && nft.metadata.format.toUpperCase()}
                    </Typography>
                    
                    <Typography gutterBottom variant="body2" component="h2" sx={{marginRight:'20px', paddingBottom:'0px'}}>
                        {t("nft-screen.resolution")}: {nft && nft.metadata && nft.metadata.resolution}
                    </Typography>
            </CardContent>
        </React.Fragment>
    )
}

ViewCard.propTypes  = {
    nft: PropTypes.object
}

const NFTMedia = ({nft}) => {
    

    return (
        <Box
            sx={{
                width: '100%'
            }}
        >
            <Grid 
                container 
                columns={{sm:12, md:12, lg:12, xl:12}}
                rowSpacing={4} 
                spacing={1}
                justifyContent="center"
            >
                <Grid 
                    item 
                    xs={12}
                    sm={12} 
                    md={11} 
                    lg={12} 
                    xl={12}
                    sx={{
                        width:'100%'
                    }}
                >
                    <Card
                        sx={{
                            width: '100%',
                            minHeight:'300px'
                        }}
                    >   
                        {
                            nft && nft.transaction && String(nft.transaction) == "PRE_MINT" ?
                                <CardActionArea
                                    sx={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        padding:'8px 8px',
                                        minHeight:'300px'
                                    }}
                                >
                                    {
                                        nft && nft.reveal.confirmed && isMinted(nft) ?
                                        <ViewCard nft={nft} />
                                        :
                                        <Box
                                            sx={{
                                                borderRadius: '8px 8px 8px 8px',
                                                boxShadow:'inset 0px 0px 20px rgba(0,0,0,.5)',
                                                minHeight:'300px',
                                                display:'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Box component={"h3"}
                                                sx={{
                                                    textAlign: 'center',
                                                    color:'#fff',
                                                    fontSize:'120px',
                                                    margin:'10px 10px',
                                                    WebkitTextStroke:'1px black'
                                                }}
                                            > 
                                                ?
                                            </Box>
                                        </Box>
                                    }
                                    
                                </CardActionArea>
                            :
                            <ViewCard nft={nft} />
                            }
                            
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

NFTMedia.propTypes = {
    nft: PropTypes.object.isRequired
}

export default NFTMedia
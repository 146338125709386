import React, { useState } from 'react'
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next'; 
import ErrorMessage from 'components/ErrorMessage';
import { useFetch } from 'hooks/useFetch';
import Details from './components/Details';
import OfferHistory from 'components/OfferHistory';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LoaderCircle from 'components/LoaderCircle';
import NFTData from './components/NFTData';
import NavInfo from './components/NavInfo';


const NFT = () => {
    const { t } = useTranslation("translate"); 
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const address = query.get("address")
    const tokenId = query.get("token_id")
    const [bidTx ,setBidTx] =  useState(null)
    const NFTurl = location.search
    const [url,setUrl]= useState(process.env.REACT_APP_URL_API+`/nft?address=${address}&token_id=${tokenId}&domain=${process.env.REACT_APP_DOMAIN}`)
    const [first,setFirst] = useState(false)
    const [historyUrl,setHistoryUrl] = useState(process.env.REACT_APP_URL_API+`/history?domain=${process.env.REACT_APP_DOMAIN}&token_id=${tokenId}&contract_address=${address}&limit=20&page=0`) 
    const [countError1,setCountError1] = useState(0);
    const [countError2,setCountError2] = useState(0);
    const {data, error, loading} = useFetch(url,countError1)
    const {data:dataHistory, error:errorHistory, loading:loadingHistory} = useFetch(historyUrl,countError2)
    console.log('dataHistory', dataHistory)
    
    const reloadPage = () => {
        window.location.reload();
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    let timeOut1 = null ;

    React.useEffect(() => {
        if(data){
            setFirst(true);
        }
    },[data]);
    
    React.useEffect(() => {
        const getNFT = async() =>{
            timeOut1 = null;
            clearTimeout(timeOut1)
            setUrl(process.env.REACT_APP_URL_API+`/nft?address=${address}&token_id=${tokenId}&domain=${process.env.REACT_APP_DOMAIN}`)
            if(!loading && error) {
                if(countError1 < 3){
                    timeOut1 = setTimeout(() =>{
                        setCountError1(countError1+1)
                        clearTimeout(timeOut1)
                        return null;
                    },5000);
                   
                }else{
                    return null;
                }
            }
        }
        getNFT();
    },[error,countError1,loading]);


    let timeOut2 = null ;

    
    React.useEffect(() => {
        const getHistory = async() =>{
            timeOut2 = null;
            clearTimeout(timeOut2)
            setHistoryUrl(process.env.REACT_APP_URL_API+`/history?domain=${process.env.REACT_APP_DOMAIN}&token_id=${tokenId}&contract_address=${address}&blockchain=${process.env.REACT_APP_NETWORK_NAME}`)
            if(!loadingHistory && errorHistory) {
                if(countError2 < 3){
                    timeOut2 = setTimeout(() =>{
                        setCountError2(countError2+1)
                        clearTimeout(timeOut2)
                        return null;
                    },5000);
                   
                }else{
                    return null;
                }
            }
        }
        getHistory();
    },[errorHistory,countError2,loadingHistory]);



    
    return (
        <Box component='section' sx={{width:'100%',height:'100%',boxSizing:'border-box'}}>

                    {
                        (loading || loadingHistory) || countError2 > 0 && countError2 < 3&&
                        <Box sx={{width:'100%',height:'100%',minHeight: '100%',boxSizing:'border-box'}}>
                            <LoaderCircle text={t('message_loader.loading')} />
                            {
                                (countError2 > 0 && countError2 < 3) &&
                                <center style={{color:'#A658D8'}}>
                                    {t('message_loader.retry_request_history')}, {t('message_loader.retry')} : {countError2}
                                </center>
                            }
                            {
                                (countError1 > 0 && countError2 < 3) &&
                                <center style={{color:'#A658D8'}}>
                                    {t('message_loader.retry_request_nft')}, {t('message_loader.retry')} : {countError1}
                                </center>
                            }
                        </Box>
                    }
                    
                    {error && (countError1 == 3) ? <ErrorMessage error={'NFT: '+error.message} /> :
                      errorHistory &&  (countError2 == 3) && <ErrorMessage error={'History: '+errorHistory.message} />
                    }
                    {bidTx &&
                    <Container maxWidth="xs" sx={{margin:'0 auto', marginBottom:'5px'}}>
                        <Alert severity="success">
                            {t('nft-screen.successful_offer')} {bidTx.bid} WAVAX ! {t('nft-screen.reload_message')}
                            <a style={{textDecoration:'none',color:'green'}} href={`${process.env.REACT_APP_SCAN}tx/${bidTx.tx}`} target="_blank" rel="noreferrer">
                                {(bidTx.tx).substring(0,8)+ '...' +(bidTx.tx).substring(58,66)}
                            </a>
                        </Alert>
                    </Container>}
                    {
                        first &&
                        <React.Fragment>
                            {!loading && !error && data && data.length > 0 && data[0] ?
                            <React.Fragment>
                                <NFTData
                                    bids = {dataHistory}
                                    setBidTx = {setBidTx}
                                    address={address}
                                    tokenId={tokenId}
                                    nft={data[0]}
                                    reloadPage = {reloadPage}
                                    NFTurl={NFTurl}
                                />
                                <NavInfo 
                                    nft={data[0]}
                                />
                            </React.Fragment>
                            :
                            <Box
                                sx={{width:'100%', height:'100%',minHeight:'300px',display:'flex', justifyContent:'center', alignItems:'center',textAlign:'center'}}
                            >
                                <Typography variant='h4' sx={{color:'#A658D8',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>
                                    {t("explore.not_found")}
                                </Typography>
                            </Box>
                            }
                        </React.Fragment>
                    }
                    <Container maxWidth='lg'>
                        <Grid container columns={{lg:12, md:12}}>
                            <Grid item lg={6} md={6} sm={1} xs={1}>
                                <Container 
                                    maxWidth='lg' 
                                    sx={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'space-between',
                                        '@media screen and (max-width:700px)':{
                                            flexDirection:'column',
                                        }
                                    }}
                                >
                                    {data && data.length > 0 &&
                                    <Box>
                                        <h1>{t('nft_details.title')}</h1>
                                        <Divider />
                                        <Container maxWidth='lg' sx={{marginTop:'2rem'}}>
                                            <Details content={data[0]} />
                                        </Container>
                                    </Box>}
                                </Container>
                            </Grid>
                            <Grid item lg={6} md={6} sm={1} xs={1} sx={{marginBottom:'2rem'}}>
                                <Container maxWidth='sm'>
                                    {dataHistory != null && dataHistory && data && data.length > 0 && data[0] &&
                                    <Box>
                                        <h1>{t('trading_history.title')}</h1>
                                        <Divider />
                                        <Container maxWidth='lg' sx={{marginTop:'2rem'}}>
                                            <OfferHistory content={dataHistory} />
                                        </Container>
                                    </Box>}
                                </Container>
                            </Grid>
                        </Grid>
                    </Container>
        </Box>
    );
}

export default NFT;

import React from 'react';
import PropTypes from 'prop-types';
import { Box,Card, CardMedia, Avatar} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getThumbnail } from 'services/Thumbail/getThumbnail';
import styled from "@emotion/styled/macro";

export const CardContentCustom = styled(Card)({
    borderRadius: '18px',
    background: 'transparent',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#FFF",
    padding:'none',
    position: "relative",
    width:'100%',
    height:'100%',
    boxShadow: "none",
    overFlow: "hidden",
    [`:hover ${CardMedia}`]: {
      backgroundColor: "rgba(0,0,0,.5)",
    },
    [`:hover ${CardMedia}`]: {
      opacity: 1,
    },
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        //transform: 'translateY(-2px)',
        /* select class */
        '& .card-collection': {
          transform: 'scale(1.06)',
        },
        '& .is-video-collection': {
          transform: 'scale(3.08)',
        }
    },
    ["@media (max-width: 320px)"]: {
      height: '330px'
    }
});


const CardCollageSixImg  = ({images,type,width,query}) => {
    const {t} = useTranslation("translate");

    const handleGetBorder = (index)=>{
        if(index == 0){
            return '8px 0px 0px 0px'
        }
        if(index == 2){
            return '0px 8px 0px 0px'
        }
        if(index == 3){
            return '0px 0px 0px 8px'
        }
        if(index == 5){
            return '0px 0px 8px 0px'
        }
        return '0px 0px'
    }

    const checkTypeAndReturnWidth = (width) =>{
        if(type == 'auction'){
            return width;
        }else
        if(type == 'nft'){
            return width;
        }else
        if((type === "profile" || type=="curators")){
            return width;
        }else
        if(type === "collections"){
            return width;
        }else{
            return width;
        }
    }

    const checkTypeAndReturnHeight = (width) =>{
        if(type == 'auction'){
            return width+320;
        }else
        if(type == 'nft'){
            return width+320;
        }else
        if((type === "profile" || type=="curators")){
            return width;
        }else
        if(type === "collections"){
            return width;
        }else{
            return width;
        }
    }
    
    return (
        <React.Fragment>
            {
                type === "nft" &&
                <Box
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                    }}
                >
                    <CardContentCustom
                        sx={{          
                        width:{xs:'90vw',sm:`${Number( (checkTypeAndReturnWidth(width) / 2) - 20)}px`,md:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`,lg:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`,xl:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`},
                        height:{xs:'90vw',sm:`${Number( (checkTypeAndReturnHeight(width) / 2) - 20)}px`,md:`${Number( (checkTypeAndReturnHeight(width) / 3) - 60)}px`,lg:`${Number( (checkTypeAndReturnHeight(width) / 3) - 60)}px`,xl:`${Number( (checkTypeAndReturnHeight(width)/ 3) - 60)}px`},
                        "@media screen and (min-width: 2880px)":{
                            width:`${Number( (checkTypeAndReturnWidth(width) / 5) - 60)}px`,
                            height:`${Number( (checkTypeAndReturnHeight(width) / 5) - 10)}px`
                        },
                        "@media screen and (min-width: 3580px)":{
                            width:'490px',
                            height:'602px'
                        }
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                display:'grid',
                                gridTemplateColumns:`repeat(3,1fr)`,
                                gridTemplateRows: '1fr 1fr',
                                position:'relative'
                            }}
                        >
                            {
                                images?.map((item,index)=>{
                                    return (
                                        index <= 5 &&
                                        <Card key={index}
                                            sx={{
                                                width: "100%",
                                                borderRadius:handleGetBorder(index)
                                            }}
                                        >
                                            {
                                            item && item.metadata && item.metadata.is_video  ?
                                            <CardMedia
                                                className={'card-collection'}
                                                component={'video'}
                                                src={item && item.thumb_url}
                                                autoPlay
                                                loop
                                                muted
                                                sx={{
                                                    height:'100%',
                                                    width:'100%',
                                                    margin:'0 auto',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                            :
                                            <CardMedia
                                                className={'card-collection'}
                                                component={'img'}
                                                src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                                                sx={{
                                                    height:'100%',
                                                    width:'100%',
                                                    margin:'0 auto',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                            }
                                        </Card>
                                    )
                                })
                            }
                            <Box
                                sx={{
                                    position:'absolute',
                                    left:'0px',top:'0px',
                                    width: "100%",
                                    height: "100%"
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'                                        
                                    }}
                                >
                                    <Link 
                                        to={`/explore?${query}`}
                                        style={{
                                            display:'flex',
                                            alignItems:'center',
                                            fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                            fontSize:'30px',
                                            fontWeight:'bold',
                                            color:'#000',
                                            textDecoration:'none',
                                            "@media screen and (maxWidth: 380px)":{
                                                fontSize:'18px',
                                            },
                                            cursor:'pointer',
                                            "a:hover":{
                                                color:'#A630D9',
                                                opacity:'0.3'
                                            },
                                            borderRadius:'8px 8px',
                                            backgroundColor:'rgba(255,255,255,0.4)',
                                            padding:'5px 5px'
                                        }}
                                    >
                                        <AddIcon sx={{fontSize:'50px',color:'#000'}} />{t("home.view_more")}
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </CardContentCustom>
                </Box>
            }
            {
                type=="collections" &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    <CardContentCustom
                        sx={{          
                        width:{xs:'90vw',sm:`${Number( (checkTypeAndReturnWidth(width) / 2) - 20)}px`,md:`${Number( (checkTypeAndReturnWidth(width) / 3) - 40)}px`,lg:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`,xl:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`},
                        height:{xs:'90vw',sm:`${Number( (checkTypeAndReturnHeight(width) / 2) - 20)}px`,md:`${Number( (checkTypeAndReturnHeight(width) / 3) - 60)}px`,lg:`${Number( (checkTypeAndReturnHeight(width) / 3) - 60)}px`,xl:`${Number( (checkTypeAndReturnHeight(width)/ 3) - 60)}px`},
                        "@media screen and (min-width: 2880px)":{
                            width:`${Number( (checkTypeAndReturnWidth(width) / 5) - 60)}px`,
                            height:`${Number( (checkTypeAndReturnHeight(width) / 5) - 60)}px`
                        },
                        "@media screen and (min-width: 3580px)":{
                            width:'490px',
                            height:'490px'
                        }
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                display:'grid',
                                gridTemplateColumns:`repeat(3,1fr)`,
                                gridTemplateRows: '1fr 1fr',
                                position:'relative'
                            }}
                        >
                            {
                                images?.map((item,index)=>{
                                    return (
                                        index <= 5 &&
                                        <Card key={index}
                                            sx={{
                                                width: "100%",
                                                borderRadius:handleGetBorder(index)
                                            }}
                                        >
                                            {
                                            item && item.metadata && item.metadata.is_video  ?
                                            <CardMedia
                                                className={'card-collection'}
                                                component={'video'}
                                                src={item && item.thumb_url}
                                                autoPlay
                                                loop
                                                muted
                                                sx={{
                                                    height:'100%',
                                                    width:'100%',
                                                    margin:'0 auto',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                            :
                                            <CardMedia
                                                className={'card-collection'}
                                                component={'img'}
                                                src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                                                sx={{
                                                    height:'100%',
                                                    width:'100%',
                                                    margin:'0 auto',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                            }
                                        </Card>
                                    )
                                })
                            }
                            <Box
                                sx={{
                                    position:'absolute',
                                    left:'0px',top:'0px',
                                    width: "100%",
                                    height: "100%"
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'                                        
                                    }}
                                >
                                    <Link 
                                        to={`/explore?${query}`}
                                        style={{
                                            display:'flex',
                                            alignItems:'center',
                                            fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                            fontSize:'30px',
                                            fontWeight:'bold',
                                            color:'#000',
                                            textDecoration:'none',
                                            "@media screen and (maxWidth: 380px)":{
                                                fontSize:'18px',
                                            },
                                            cursor:'pointer',
                                            "a:hover":{
                                                color:'#A630D9',
                                                opacity:'0.3'
                                            },
                                            borderRadius:'8px 8px',
                                            backgroundColor:'rgba(255,255,255,0.4)',
                                            padding:'5px 5px'
                                        }}
                                    >
                                        <AddIcon sx={{fontSize:'50px',color:'#000'}} />{t("home.view_more")}
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </CardContentCustom>
                </Box>
            }
            {
                (type === "profile" || type=="curators") &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    <CardContentCustom
                        sx={{          
                            width:{xs:'88vw',sm:`${Number( (checkTypeAndReturnWidth(width) / 2) - 20)}px`,md:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`,lg:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`,xl:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`},
                            height:{xs:'88vw',sm:`${Number( (checkTypeAndReturnHeight(width) / 2) + 20)}px`,md:`370px`},
                        "@media screen and (min-width: 2880px)":{
                            width:`${Number( (checkTypeAndReturnWidth(width) / 5) - 60)}px`,
                            height:`${Number( (checkTypeAndReturnHeight(width) / 5) - 60)}px`
                        },
                        "@media screen and (min-width: 3580px)":{
                            width:'490px',
                            height:'490px'
                        }
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                display:'grid',
                                gridTemplateColumns:`repeat(3,1fr)`,
                                gridTemplateRows: '1fr 1fr',
                                position:'relative'
                            }}
                        >
                            {
                                images?.map((item,index)=>{
                                    return (
                                        index <= 5 &&
                                        <Card key={index}
                                            sx={{
                                                width: "100%",
                                                borderRadius:handleGetBorder(index)
                                            }}
                                        >
                                            <Avatar
                                                src={item && item.banner_url && item.banner_url}
                                                sx={{
                                                    borderRadius:'0px 0px',
                                                    position:'relative',
                                                    height:'100%',
                                                    width:'100%',
                                                    margin: '0 auto',
                                                }}
                                            />
                                            
                                        </Card>
                                    )
                                })
                            }
                            <Box
                                sx={{
                                    position:'absolute',
                                    left:'0px',top:'0px',
                                    width: "100%",
                                    height: "100%"
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'                                        
                                    }}
                                >
                                    <Link 
                                        to={`/explore?${query}`}
                                        style={{
                                            display:'flex',
                                            alignItems:'center',
                                            fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                            fontSize:'30px',
                                            fontWeight:'bold',
                                            color:'#000',
                                            textDecoration:'none',
                                            "@media screen and (maxWidth: 380px)":{
                                                fontSize:'18px',
                                            },
                                            cursor:'pointer',
                                            "a:hover":{
                                                color:'#A630D9',
                                                opacity:'0.3'
                                            },
                                            borderRadius:'8px 8px',
                                            backgroundColor:'rgba(255,255,255,0.4)',
                                            padding:'5px 5px'
                                        }}
                                    >
                                        <AddIcon sx={{fontSize:'50px',color:'#000'}} />{t("home.view_more")}
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </CardContentCustom>
                </Box>
            }
            {
                type=="auction" &&
                <React.Fragment>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                        }}
                    >
                        <CardContentCustom
                            sx={{          
                            width:{xs:'90vw',sm:`${Number( (checkTypeAndReturnWidth(width) / 2) - 20)}px`,md:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`,lg:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`,xl:`${Number( (checkTypeAndReturnWidth(width) / 3) - 60)}px`},
                            height:{xs:'90vw',sm:`${Number( (checkTypeAndReturnHeight(width) / 2) - 20)}px`,md:`${Number( (checkTypeAndReturnHeight(width) / 3) - 28)}px`,lg:`${Number( (checkTypeAndReturnHeight(width) / 3) - 28)}px`,xl:`${Number( (checkTypeAndReturnHeight(width)/ 3) - 28)}px`},
                            "@media screen and (min-width: 2880px)":{
                                width:`${Number( (checkTypeAndReturnWidth(width) / 5) - 60)}px`,
                                height:`${Number( (checkTypeAndReturnHeight(width) / 5) - 10)}px`
                            },
                            "@media screen and (min-width: 3580px)":{
                                width:'490px',
                                height:'602px'
                            }
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display:'grid',
                                    gridTemplateColumns:`repeat(3,1fr)`,
                                    gridTemplateRows: '1fr 1fr',
                                    position:'relative'
                                }}
                            >
                                {
                                    images?.map((item,index)=>{
                                        return (
                                            index <= 5 &&
                                            <Card key={index}
                                                sx={{
                                                    width: "100%",
                                                    borderRadius:handleGetBorder(index)
                                                }}
                                            >
                                                {
                                                item && item.metadata && item.metadata.is_video  ?
                                                <CardMedia
                                                    className={'card-collection'}
                                                    component={'video'}
                                                    src={item && item.thumb_url}
                                                    autoPlay
                                                    loop
                                                    muted
                                                    sx={{
                                                        height:'100%',
                                                        width:'100%',
                                                        margin:'0 auto',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                                :
                                                <CardMedia
                                                    className={'card-collection'}
                                                    component={'img'}
                                                    src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                                                    sx={{
                                                        height:'100%',
                                                        width:'100%',
                                                        margin:'0 auto',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                                }
                                            </Card>
                                        )
                                    })
                                }
                                <Box
                                    sx={{
                                        position:'absolute',
                                        left:'0px',top:'0px',
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center'                                        
                                        }}
                                    >
                                        <Link 
                                            to={`/explore?${query}`}
                                            style={{
                                                display:'flex',
                                                alignItems:'center',
                                                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                                fontSize:'30px',
                                                fontWeight:'bold',
                                                color:'#000',
                                                textDecoration:'none',
                                                "@media screen and (maxWidth: 380px)":{
                                                    fontSize:'18px',
                                                },
                                                cursor:'pointer',
                                                "a:hover":{
                                                    color:'#A630D9',
                                                    opacity:'0.3'
                                                },
                                                borderRadius:'8px 8px',
                                                backgroundColor:'rgba(255,255,255,0.4)',
                                                padding:'5px 5px'
                                            }}
                                        >
                                            <AddIcon sx={{fontSize:'50px',color:'#000'}} />{t("home.view_more")}
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContentCustom>
                    </Box>
                </React.Fragment>
            }
        </React.Fragment>
    );
};

CardCollageSixImg .propTypes = {
    images: PropTypes.array,
    type: PropTypes.string,
    width: PropTypes.any,
    query: PropTypes.string
};

export default CardCollageSixImg ;


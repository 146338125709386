import React, { useContext } from 'react'
import { Box, CardMedia } from '@mui/material';
import mintNFTLogo from 'assets/images/mint/MINTEAR-NFT-1.jpg';
import mintCollectionLogo from 'assets/images/mint/COLECCION-1.jpg';
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Context } from 'hooks/WalletContext';
import { useTranslation } from 'react-i18next';

const CreateItem = styled(Link)`
    text-decoration: none;
    color: #000;
    &:hover {
        color: '#000',
    };
`

const Create = () => {
    const { t } = useTranslation("translate");
    const {data}=useContext(Context);
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
    } ,[])


    return (
        <React.Fragment>
            <Box
                component='h1'
                sx={{
                    textAlign: 'center',
                    fontSize: '2rem',
                    fontWeight: 600,
                }}
            >
                {t('create_view.title')}
            </Box>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                alignContent='center'
                sx={{
                    gap:'200px',
                    marginTop:'5rem',
                    '@media (max-width: 900px)': {
                        flexDirection: 'column',
                        gap: '10px',
                        marginTop: '0',
                        marginBottom: '2rem',
                    }
                }}
            >
                
                <CreateItem to='/create/create-collection'>
                    <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        sx={{
                            width:'330px',
                            height:'370px',
                            maxHeight:'370px',
                            border:'1px solid #e6e6e6',
                            boxShadow:'0px 0px 10px #e6e6e6',
                            borderRadius:'10px',
                            boxSizing:'border-box',
                            cursor:'pointer',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow:'0px 0px 10px #fff',
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                padding:'1rem'
                            }}
                        >
                            <CardMedia
                                component="img"
                                src={mintCollectionLogo}
                                sx={{
                                    borderRadius:'10px',
                                    minHeight:'100px'
                                }}
                            />
                        </Box>  
                        <Box>
                            <Box
                                component='h3'
                                sx={{
                                    margin:'0px 0px',
                                    padding:'0px 0px',
                                    fontSize:'1.5rem',
                                    textAlign:'center',
                                    color:'#000'
                                }}
                            >
                               {t('create_view.create_auction')}
                            </Box>
                        </Box>
                    </Box>
                </CreateItem>
                <CreateItem to={`/create/select-collection?address=${data.userAccount}`}>
                    <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        sx={{
                            width:'330px',
                            height:'370px',
                            maxHeight:'370px',
                            border:'1px solid #e6e6e6',
                            boxShadow:'0px 0px 10px #e6e6e6',
                            borderRadius:'10px',
                            boxSizing:'border-box',
                            cursor:'pointer',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow:'0px 0px 10px #fff',
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                padding:'1rem'
                            }}
                        >
                            <CardMedia
                                component="img"
                                src={mintNFTLogo}
                                sx={{
                                    borderRadius:'10px',
                                    minHeight:'100px'
                                }}
                            />
                        </Box>  
                        <Box>
                            <Box
                                component='h3'
                                sx={{
                                    margin:'0px 0px',
                                    padding:'0px 0px',
                                    fontSize:'1.5rem',
                                    textAlign:'center',
                                    color:'#000'
                                }}
                            >
                               {t('create_view.create_nft')}
                            </Box>
                        </Box>
                    </Box>
                </CreateItem>
            </Box>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                alignContent='center'
                sx={{
                    gap:'200px',
                    marginTop:'5rem',
                    '@media (max-width: 600px)': {
                        flexDirection: 'column',
                        gap: '10px',
                        marginTop: '0',
                        marginBottom: '2rem',
                    }
                }}
            >
                
                {/* <CreateItem to={`/create/select-collection-pre-mint?address=${data.userAccount}`}>
                <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        sx={{
                            width:'330px',
                            height:'370px',
                            maxHeight:'370px',
                            border:'1px solid #e6e6e6',
                            boxShadow:'0px 0px 10px #e6e6e6',
                            borderRadius:'10px',
                            boxSizing:'border-box',
                            cursor:'pointer',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow:'0px 0px 10px #fff',
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                padding:'1rem'
                            }}
                        >
                            <CardMedia
                                component="img"
                                src={mintNFTLogo}
                                sx={{
                                    borderRadius:'10px',
                                    minHeight:'100px'
                                }}
                            />
                        </Box>  
                        <Box>
                            <Box
                                component='h3'
                                sx={{
                                    margin:'0px 0px',
                                    padding:'0px 0px',
                                    fontSize:'1.5rem',
                                    textAlign:'center',
                                    color:'#000'
                                }}
                            >
                                {t('create_view.create_pre_mint_nft')}
                            </Box>
                        </Box>
                    </Box>
                </CreateItem> */}
            </Box>
        </React.Fragment>
    )
}

export default Create
import React, { useContext, useState } from 'react'
import { Box, Container } from '@mui/material'
import TabSelector from 'components/TabSelector';
import UserRegisteredTab from './components/UserRegisteredTab';
import AuctionSaleTab from './components/AuctionSaleTab';
import NFTUserRegisteredTab from './components/NFTUserRegisteredTab';
import Categories from './components/Categories';
import Sections from './components/Sections';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { sign } from 'services/Blockchain/signMessage';
import { Context } from 'hooks/WalletContext';
import Alert from '@mui/material/Alert';
// import ArtistsAndCurators from './components/ArtistsAndCurators';


const AdminDashboard = () => {
    const { data } = useContext(Context);
    const [activeTab, setActiveTab] = useState(1)
    const initialState = [
        {
            name: 'Usuarios registrados',
            active: true,
            number: 1
        },
        {
            name: 'Ventas/Subastas',
            active: false,
            number: 2
        },
        {
            name: 'Usuarios con NFTs',
            active: false,
            number: 3
        },
        {
            name: 'Categorias',
            active: false,
            number: 4
        },
        {
            name: 'Sección',
            active: false,
            number: 5
        },
        // {
        //     name: 'Artistas/Curadores',
        //     active: false,
        //     number: 6
        // }
    ]

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const update = async() => {
        await sign(data.userAccount,data.provider,"Update contract","update")
        localStorage.setItem('new_contract','updated')
        window.location.reload()
    }

    return (
        <Box
        sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }}>
            <Box component='h1'>Dashboard</Box>
            <Container>
                <Alert severity="info">We are updating the dashboard, some functions are disabled!</Alert>
                { !localStorage.getItem('new_contract') &&
                <div onClick = { () => { update()}}>

                    Update contract
                    <IconButton aria-label="update" onClick = { () => { update()}}>
                         <RefreshIcon />
                    </IconButton>
                </div>
                }
                <TabSelector items={initialState} setUpdate={setActiveTab} showSelector={false} />
                <Box 
                    sx={{
                        marginBottom:'2rem'
                    }}
                >
                    { activeTab == 1 &&  <UserRegisteredTab />}
                    { activeTab == 2 &&  <AuctionSaleTab />}
                    { activeTab == 3 &&  <NFTUserRegisteredTab />}
                    { activeTab == 4 &&  <Categories />}
                    { activeTab == 5 &&  <Sections />}
                    {/* { activeTab == 6 &&  <ArtistsAndCurators />} */}

                </Box>
            </Container>
        </Box>
    )
}

export default AdminDashboard
import React, { useEffect, useState } from 'react';
import {Box,CardMedia,Grid,Card} from '@mui/material';
import UniquePeopleLogo from 'assets/images/png/slogan_blanco_1.png';
import ArtcryptedWhiteLogo from 'assets/images/png/logosolo_blanco.png';
import { nfts } from 'mocks/nfts';
import { Link } from 'react-router-dom';
import { presentationHome } from 'api/sanity';
import BlockContent from '@sanity/block-content-to-react';
import PropTypes from 'prop-types';
import { getThumbnail } from 'services/Thumbail/getThumbnail';
import styled from "@emotion/styled/macro";

export const BackgroundNewCard = styled(Card)({
    borderRadius: '8px',
    backgroundSize: 'cover',
    background:'transparent',
    backgroundRepeat: 'no-repeat',
    color: '#FFF',
    position: 'relative',
    cursor: 'pointer',
    border: '1px solid transparent',
    boxShadow: 'none',
    transition: 'all 0.3s ease-in-out',
    width:'270px',
    height:'270px',
    overflow: 'hidden',
    "@media screen and (max-width: 1300px)":{
        width:'250px',
        height:'250px',
    },
    "@media screen and (max-width: 750px)":{
        width:'160px',
        height:'160px',
    },
    "@media screen and (max-width: 375px)":{
        width:'130px',
        height:'130px',
    },
    ["@media (max-width: 320px)"]: {
      height: '330px'
    }
  });

const ShowTitle = ({titleHeader}) =>{
    return (
        <React.Fragment>
            <Box
                sx={{
                    display:{xs:'none',sm:'none',md:'block'},
                    width:'100%',
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    color:'#FFF',
                    marginBottom:'20px',
                    fontSize:'6.1rem',
                    lineHeight:'1.099',
                    '& > p':{
                        fontSize:{xs:'30px',sm:'6.1rem',md:'6.1rem',lg:'6.1rem'},
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.099'
                    },
                    "& > h6":{
                        width:'100%',
                        fontSize:'1.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.099'
                    },
                    "& > h5":{
                        width:'100%',
                        fontSize:'2.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.099'
                    },
                    "& > h4":{
                        width:'100%',
                        fontSize:'3.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',  
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.099'
                    },
                    "& > h3":{
                        width:'100%',
                        fontSize:'4.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.099'
                    },
                    "& > h2":{
                        width:'100%',
                        fontSize:'5.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.099'
                    },
                    "& > h1":{
                        fontSize:  {sm:'4.1rem',md: '4.1rem', lg:'4rem', xl : '6.1rem'},
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.099'
                    }
                }}
            >
                {
                    titleHeader != null && titleHeader.desktop != null && titleHeader.desktop.title != null &&
                    <BlockContent blocks={titleHeader.desktop.title} projectId="87sy9d0n" />
                }
            </Box>
            <Box
                sx={{
                    display:{xs:'none',sm:'block',md:'none',lg:'none',xl:'none'},
                    width:'100%',
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    color:'#FFF',
                    marginBottom:'20px',
                }}
            >
                {
                    titleHeader != null && titleHeader.tablet != null && titleHeader.tablet.title != null &&
                    <BlockContent blocks={titleHeader.tablet.title} projectId="87sy9d0n" />
                }
            </Box>
            <Box
                sx={{
                    display:{xs:'auto',sm:'none',md:'none',lg:'none',xl:'none'},
                    width:'100%',
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    color:'#FFF',
                    marginBottom:'20px',
                    
                }}
            >
                {
                    titleHeader != null && titleHeader.mobile != null && titleHeader.mobile.title != null &&
                    <BlockContent blocks={titleHeader.mobile.title} projectId="87sy9d0n" />
                }
            </Box>
    </React.Fragment>
    )
}

ShowTitle.propTypes = {
    titleHeader: PropTypes.any
}

const ShowSubTitle = ({titleHeader}) =>{
    return (
        <React.Fragment>
            <Box
                sx={{
                    display:{xs:'none',sm:'none',md:'block'},
                    width:'100%',
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    color:'#0D0D0D',
                    marginBottom:'20px',
                    fontSize:'3.1rem',
                    marginTop:'0px',
                    lineHeight:'1.099',
                    '& > p':{
                        fontSize:'3.1rem',
                        marginTop:'0px',
                        lineHeight:'1.099'
                    },
                    "& > h6":{
                        width:'100%',
                        fontSize:'1.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.099'
                    },
                    "& , h5":{
                        width:'100%',
                        fontSize:{ lg:'1.8rem', xl : '2.1rem'},
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.1'
                    },
                    "& , h4":{
                        width:'100%',
                        fontSize:'3.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',  
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.1'
                    },
                    "& , h3":{
                        width:'100%',
                        fontSize:'4.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.1'
                    },
                    "& , h2":{
                        width:'100%',
                        fontSize:'5.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.1'
                    },
                    "& , h1":{
                        fontSize:'6.1rem',
                        marginTop:'0px',
                        marginBottom:'0px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        lineHeight:'1.1'
                    }
                }}
            >
                {
                    titleHeader != null && titleHeader.desktop != null && titleHeader.desktop.subtitle != null &&
                    <BlockContent blocks={titleHeader.desktop.subtitle} projectId="87sy9d0n" />
                }
            </Box>
            <Box
                sx={{
                    display:{xs:'none',sm:'block',md:'none',lg:'none',xl:'none'},
                    width:'100%',
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    color:'#0D0D0D',
                    marginBottom:'20px',
                }}
            >
                {
                    titleHeader != null && titleHeader.tablet != null && titleHeader.tablet.subtitle != null &&
                    <BlockContent blocks={titleHeader.tablet.subtitle} projectId="87sy9d0n" />
                }
            </Box>
            <Box
                sx={{
                    display:{xs:'auto',sm:'none',md:'none',lg:'none',xl:'none'},
                    width:'100%',
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    color:'#0D0D0D',
                    marginBottom:'20px',
                    
                }}
            >
                {
                    titleHeader != null && titleHeader.mobile != null && titleHeader.mobile.subtitle != null &&
                    <BlockContent blocks={titleHeader.mobile.subtitle} projectId="87sy9d0n" />
                }
            </Box>
    </React.Fragment>
    )
}

ShowSubTitle.propTypes = {
    titleHeader: PropTypes.any
}


const Presentation = ({content}) =>{
    const [readyUnique, setReadyUnique] = useState(false);
    const [titleHeader, setTitleHeader] = useState([0])
    useEffect(()=>{
        presentationHome().then(response=>{setTitleHeader(response[0])})
        .catch(error=>console.error(error))
    },[])
    return (
        <Grid container columns={{xs:12,sm:12,md:12,lg:12,xl:12}}
            sx={{
                width:'100%',
                height:readyUnique?'100%':'100vh',
                display:'flex',
                alignItems:'center'
            }}
        >
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                className={readyUnique ? 'fadeInLeft1':''}
                sx={{
                    display:readyUnique ?'auto':'none'
                }}
            >
                <Box
                    sx={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'center',
                        mt:{xs:'40px',sm:'40px',md:'40px',lg:'0px',xl:'0px'}
                    }}
                >
                    <Box
                        sx={{
                            width:{xs:'61%',sm:'58%',md:'58%',lg:'90%',xl:'70%'}
                        }}
                    >
                        <CardMedia
                            component="img"
                            src={UniquePeopleLogo}
                            onLoad={()=>setReadyUnique(true)}
                            sx={{display:'none'}}
                        />
                        <ShowTitle titleHeader={titleHeader}/>
                        <ShowSubTitle titleHeader={titleHeader}/>
                        <Box
                            sx={{
                                width:'100%',
                                display:'none',
                                justifyContent:'center'
                            }}
                        >
                            <CardMedia
                                component={"img"}
                                src={ArtcryptedWhiteLogo}
                                sx={{
                                    width:{xs:'140px',sm:'200px',md:'200px',lg:'200px',xl:'200px'},
                                    marginRight:{xs:'0px',sm:'0px',md:'50px'},
                                   
                                }}
                            />
                        </Box>
                           
                    </Box>
                    
                </Box>


            </Grid>
                
       

            <Grid  item xs={12} sm={12} md={12} lg={6} xl={6}
                sx={{
                    width:'100%',
                    overflow:'hidden'
                }}
            >
                <Box
                    sx={{
                        width:'75%',
                        height:'100%',
                        display:'flex',
                        alignItems:'center',
                        margin:'0 auto',
                        "@media screen and (max-width: 1568px)":{
                            width:'95%'
                        }                    
                    }}
                >
                    <Grid container columns={{xs:12,sm:12,md:12,lg:12,xl:12}}
                        sx={{
                            width:'100%',
                            mt:{xs:'0px',sm:'0px',md:'5px'}
                        }}
                    >
                        {
                            readyUnique && content?.slice(0,4).map((item,index)=>{
                                return  <Grid key={index} item xs={6} sm={6} md={6} lg={6} xl={6} 
                                    className={'presentation-fadeIn'+index}
                                    sx={{
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        mt:index==0 || index == 2 ?'0px':'20px',
                                        mb:index==0 || index == 2 ?'0px':'20px'
                                    }}
                                >       
                                    <BackgroundNewCard>
                                        <Link
                                            to={`/nft?address=${item.project_key}&token_id=${item.token_id}&domain=${process.env.REACT_APP_DOMAIN}`}
                                            style={{textDecoration:'none'}}
                                        >
                                            <CardMedia
                                                className={'card-collection'}
                                                component={item && item.metadata.is_video ? 'video' : 'img'}
                                                src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                                                autoPlay
                                                loop
                                                muted
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        </Link>
                                    </BackgroundNewCard> 
                                </Grid>
                            })
                        
                        }
                    </Grid>
                </Box>
            </Grid>
      
        </Grid>
    );
}

Presentation.defaultProps = {
    content: nfts,
}

Presentation.propTypes = {
    content: PropTypes.array,
}

export default Presentation;
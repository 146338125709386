import Web3 from 'web3'
import Contract from './Collectibles.json'

export const deploy = async(name,symbol,address,percentages,from,provider) => {
    let web3 = new Web3(provider);
    let contract = new web3.eth.Contract(Contract.abi)
    console.log(Contract)
    return  contract.deploy({
        data :Contract.bytecode,
        arguments : [
          name, symbol,address,percentages
        ]
     }).send({
       from : from
     })
}
import React from 'react'
import Avatar from '@mui/material/Avatar'
import CardMedia from '@mui/material/CardMedia'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import { Background, BigTitle, DisplayOver,DisplayOverBottom } from './styles/styles'
import { Link } from 'react-router-dom';
import { collections } from 'mocks/collections'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getThumbnail } from 'services/Thumbail/getThumbnail';

const CollectionCard = ({showBtnAll, content, limit,loadingCollection,setNewRequest,infinityScroll}) => {
    const {t} = useTranslation("translate");


    let section = 'collectionCards';
    let allCards = document.querySelectorAll("#collectionCards .collectionCard");
    let ultimo = null;

    React.useEffect(()=>{
        if(!loadingCollection){
            let observerNFtExplore = new IntersectionObserver((cards)=>{
                cards.forEach((card)=>{
                    if(card.isIntersecting){
                        observerNFtExplore.unobserve(ultimo)
                        infinityScroll();
                    }
                })
            },
            {
                rootMargin:'0px 0px 270px 0px',
                threshold:1.0
            })

            allCards = document.querySelectorAll("#collectionCards .collectionCard");
            if(allCards && allCards.length > 0){
                ultimo = allCards[allCards.length-1];
                setNewRequest(false) 
                observerNFtExplore.observe(allCards[allCards.length-1])
            }
        }
    },[allCards,loadingCollection,limit])

    
    if(content.length === 0) {
        return (
            <Box
                sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}
            >
                <BigTitle>
                    <Typography variant='h4' sx={{color:'#A658D8',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>
                        {t("explore.not_found")}
                    </Typography>
                </BigTitle>
            </Box>
     
        )
    }
    
    return (
        <>
        <Container maxWidth='xl' sx={{marginTop:'1.5rem'}}>
            <Grid 
                container 
                columns={{xs:12,sm:12, md:12, lg:12, xl:12}}
                rowSpacing={"20px"} 
                spacing={"20px"}
                id={section}
            >
                {content.slice(0, limit).map((item, index)=>(
                <Grid 
                    key={index} 
                    item 
                    xs={12}
                    sm={6} 
                    md={6} 
                    lg={3} 
                    xl={3}
                    sx={{
                        width:'100%'
                    }}
                    className={"collectionCard"}
                >
                    <Link to={`/collection-buy?address=${item.project_key}`}>
                        <Background>
                            <CardMedia
                                className={'card-collection'}
                                component={item && item.is_video ? 'video' : 'img'}
                                src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                                autoPlay
                                loop
                                muted
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                            <DisplayOver>
                                <BigTitle>
                                    <Box
                                        display='flex'    
                                        flexDirection='column'
                                        justifyContent='space-between'
                                        sx={{
                                            // height:'45vh',
                                            height:'470px',
                                            '@media screen and (max-width: 768px)': {
                                                width:'100%',
                                            },
                                            '@media screen and (max-width: 430px)': {
                                                height:'270px',
                                            }
                                        }}
                                    >
                                    </Box>
                                </BigTitle>
                            </DisplayOver>
                            <DisplayOverBottom>
                                <Box
                                    sx={{
                                        padding:'15px',
                                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                        display:'flex',
                                        flexDirection:'column',
                                    }}
                                >
                                    <Box
                                        className="notranslate"
                                        sx={{
                                            width:'100%',
                                            display:'flex',
                                            justifyContent:'flex-start'
                                        }}
                                    >
                                        <Tooltip title={item.name}  placement="top">
                                            <Box
                                                sx={{
                                                    width:'auto',
                                                    boxSizing:'border-box',
                                                    maxWidth:'100%'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width:'100%',
                                                        boxSizing:'border-box',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        fontWeight:600, 
                                                        fontSize:'25px',
                                                        backgroundColor:'rgba(0, 0, 0, 0.2)',
                                                        padding:'0.5rem',
                                                        borderRadius: '10px',
                                                    }}
                                                >
                                                    {item.name}
                                                </Box>
                                            </Box>
                                        </Tooltip>
                                    </Box> 

                                    <Box
                                        sx={{
                                            width:'100%',
                                            mt:'8px',
                                            display:'flex',
                                            justifyContent:'flex-start'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width:'auto',
                                                boxSizing:'border-box',
                                                maxWidth:'100%',
                                                display:'flex',
                                                justifyContent:'flex-start',
                                                backgroundColor:'rgba(0, 0, 0, 0.2)',
                                                padding:'0.5rem',
                                                borderRadius: '999px',
                                                gap:'0.5rem',
                                            }}
                                        >
                                            <Avatar variant='circular' src={item.user.profile_pic_url} />
                                            <Box
                                                className="notranslate"
                                                sx={{
                                                    width:'100%',
                                                    m:'auto 0',
                                                    boxSizing:'border-box',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    fontWeight:600, 
                                                    fontSize:'16px'
                                                }}
                                            >
                                                {
                                                    item && item.user && item.user.username ? item.user.username
                                                    :
                                                    item && item.owner && String(item.owner ).substring(0,5)+ '...' + String(item.owner).substring(38,54)
                                                }
                                            </Box>
                                        </Box>
                                    </Box> 
                                </Box>
                            </DisplayOverBottom>
                        </Background>
                    </Link>
                </Grid>))}
            </Grid>
        </Container>
        {showBtnAll &&
        (<Container 
            maxWidth='sm' 
            sx={{
                display: 'flex',
                marginTop:'2rem',
                justifyContent:'center',
            }}
        >
            <Button
                variant="outlined"
                type="button"
                sx={{
                    borderRadius:'9999px',
                    backgroundColor:'#fff',
                    color:'#000',
                    border:'1px solid #e3e3e3',
                    fontSize:'18px',
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    '&:hover':{
                        backgroundColor:'#000',
                        transition:'background-color 0.3s ease-in-out',
                        color:'#fff',
                        border:'1px solid #000',
                    }
                }}
            >
                View all Collections
            </Button>
        </Container>)}
        </>
    )

}

CollectionCard.defaultProps = {
    showBtnAll: false,
    content: collections,
    limit: 8,
    loadingCollection: false,
    infinityScroll: ()=>{console.log("");},
    setNewRequest: ()=>{console.log("");}
}

CollectionCard.propTypes = {
    showBtnAll: PropTypes.bool,
    content: PropTypes.array,
    limit: PropTypes.number,
    loadingCollection : PropTypes.bool,
    infinityScroll: PropTypes.func,
    setNewRequest: PropTypes.func   
}

export default CollectionCard
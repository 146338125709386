import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SideBar from 'components/SideBar';
import { useNavigate ,useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NFTsCard from 'components/NFTsCard';
import CollectionCard from 'components/CollectionCard';
import ProfileCard from 'components/ProfileCard';
import {useFetch} from 'hooks/useFetch';
import styled from '@emotion/styled';
import LoaderCollection from 'components/LoaderCollection';
import ErrorMessage from 'components/ErrorMessage';
import LoaderNFT from 'components/LoaderNFT';
import LoaderProfile from 'components/LoaderProfile';
import PropTypes from 'prop-types'

const ActiveTag = styled(Box)`
    border-bottom: ${props => props.active == "true" ? '3px solid #000' : 'null'};
    font-weight: ${props => props.active == "true" ? 'bold' : 'null'};
    cursor: pointer;
    //background-color: ${props => props.active == "true" ? '#F344A1' : '#000'};
    ${props => props.styles}
`

const ChoiceError = ({
    byNFT,
    NFTError,
    byCollection,
    error,
    byProfile,
    errorProfileData
    }) =>{
    return (
        <React.Fragment>
            {byNFT && NFTError && <Box><ErrorMessage error={NFTError.message} /></Box>}
            {byCollection && error && <Box><ErrorMessage error={error.message} /></Box>}
            {byProfile && errorProfileData && <Box><ErrorMessage error={errorProfileData.message} /></Box>}
        </React.Fragment>
    )
}

ChoiceError.propTypes = {
    byNFT: PropTypes.bool,
    NFTError: PropTypes.any,
    byCollection:PropTypes.bool,
    error: PropTypes.any,
    byProfile:PropTypes.bool,
    errorProfileData: PropTypes.any
}

const ChoiceLoader = ({
    openFilters,
    byNFT,
    NFTLoading,
    NFTError,
    byCollection,
    loading,
    byProfile,
    loadingProfileData
    }) =>{
    return (
        <React.Fragment>
            {byNFT && NFTLoading && !NFTError && <Box><LoaderNFT openFilters={openFilters} /></Box>}
            {byCollection && loading && <Box><LoaderCollection /></Box>}
            {byProfile && loadingProfileData && <Box><LoaderProfile /></Box>}
        </React.Fragment>
    )
}

ChoiceLoader.propTypes = {
    openFilters: PropTypes.bool,
    byNFT: PropTypes.bool,
    NFTLoading: PropTypes.bool,
    NFTError: PropTypes.any,
    byCollection: PropTypes.bool,
    loading: PropTypes.bool,
    byProfile: PropTypes.bool,
    loadingProfileData: PropTypes.bool
}

const Explore = () => {
    const { t } = useTranslation("translate");
    const history = useNavigate();
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    
    const format = query.get('format')
    const rangeBottom = query.get('PRICE_RANGE_BOTTOM')
    const rangeTop = query.get('PRICE_RANGE_TOP')
    const onSale = query.get('ON_SALE')
    const liveAuction = query.get('LIVE_AUCTION')


    const key_name = query.get('key_name')
    const key_val = query.get('key_val')
    const viewAll = query.get('view_all')

    let listNFT = 100;
    const limitNFTRef = useRef(listNFT);
    const countNfts = useRef(listNFT);
    const pageNftRef = useRef(0);
    const [sliceNFT,setSliceNFT] = useState(10);
    const [newContent,setNewContent] = useState([]);

    let listProfile = 100;
    const limitProfileRef = useRef(listProfile);
    const countProfile = useRef(listProfile);
    const pageProfileRef = useRef(0);
    const [sliceProfile,setSliceProfile] = useState(10);
    const [newProfile,setNewProfile] = useState([]);

    let listCollection = 100;
    const limitCollectionRef = useRef(listCollection);
    const countCollection = useRef(listProfile);
    const pageCollectionRef = useRef(0);
    const [sliceCollection,setSliceCollection] = useState(10);
    const [newCollection,setNewCollection] = useState([]);

    const [firstRequest,setFirstRequest] = useState(true);
    const changeFilter = useRef(false)

    const handleCheckTab = () =>{
        if(viewAll && String(viewAll).includes('collection')){
            return 'collection';
        }
        if(viewAll && String(viewAll).includes('profile')){
            return 'profile';
        }
        return 'nft';
    }

    const tabActive = useRef(handleCheckTab())



    const handleChoseLink = () =>{
        if(rangeBottom != undefined && rangeBottom != null && rangeTop != undefined && rangeTop != null){
            return `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&PRICE_RANGE_BOTTOM=${rangeBottom}&PRICE_RANGE_TOP&key_val=${rangeTop}`
        }
        if(onSale && (String(onSale).toUpperCase() === "TRUE" )){
            return `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&ON_SALE=${onSale}`
        }
        if(liveAuction && (String(liveAuction).toUpperCase() === "TRUE" )){
            return `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&LIVE_AUCTION=${liveAuction}`
        }
        if(format && format === "IMAGE"|| format === "VIDEO" || format === "DOCUMENT" || format === "TRACK" ){
            return `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&format=${format}`
        }
        if(key_name && key_val && String(key_name).toUpperCase() === "SEARCH"){
            return `${process.env.REACT_APP_URL_API}/nft?&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=SEARCH&key_val=news&domain=${process.env.REACT_APP_DOMAIN}`
        }
        if(key_name && key_val && String(key_name).toUpperCase() === "CATEGORY"){
            return `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=CATEGORY&key_val=${key_val}`
        }
        if(query.get('SLUG') != null && query.get('SLUG') != ''){
            return `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&SLUG=${query.get('SLUG')}`
        }
        return `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=SEARCH&key_val=news`
    }
    
    const [urlNFT, setUrlNFT] = useState(handleChoseLink())
    useEffect(()=>{
        setUrlNFT(handleChoseLink());
    },[])

    const choiceActive=()=>{
        if(viewAll && viewAll != null && viewAll.includes('profiles')) { 
            return true
        }else
        if(viewAll && viewAll != null && viewAll.includes('collections')){
            return false;
        }
    }

    const [urlProject, setUrlProject] = useState(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&key_name=NEWS&key_val=NEWS&limit=${limitCollectionRef.current}&page=${pageCollectionRef.current}&order=created`)
    const [urlProfile, setUrlProfile] = useState(`${process.env.REACT_APP_URL_API}/user?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitProfileRef.current}&page=${pageProfileRef.current}&order=created&role=2`)
    const [byProfile, setByProfile] =useState(choiceActive())
    const [activeProfile, setActiveProfile] = useState(choiceActive())

    const [byCollection, setByCollection] = useState(((viewAll != null) && viewAll.includes('collections')) ? true :false)
    const [activeCollection, setActiveCollection] = useState(((viewAll != null) && viewAll.includes('collections')) ? true :false)

    const [byNFT, setByNFT] = useState(((viewAll != null) && !viewAll.includes('nfts')) ? false :true)
    const [activeNFT, setActiveNFT] = useState(((viewAll != null) && !viewAll.includes('nfts')) ? false :true)
    const [openFilters, setOpenFilters] = React.useState(true);

    const handleSlug = ()=>{
        setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&SLUG=${query.get('SLUG')}`)
    }

    const handleResetLimitAndPAgeNFT = () =>{
        limitNFTRef.current = listNFT;
        countNfts.current = listNFT;
        pageNftRef.current = 0;
        setSliceNFT(10)
    }
    
    let {data, error, loading} = useFetch(urlProject) // collections
    let {data:NFTData, error:NFTError, loading:NFTLoading} = useFetch(urlNFT) // nfts
    let {data:profileData, error:errorProfileData, loading:loadingProfileData} = useFetch(urlProfile) // profiles

    const sortby = (str)=>{
        if(str === "nft" && !NFTLoading){
            setByNFT(true)
            setByProfile(false)
            setByCollection(false)
            setActiveNFT(true)
            setActiveCollection(false)
            setActiveProfile(false)
            tabActive.current = "nft"
            history(`/explore`)
            return 0;
        }
        if(str === "collection"){
            setByNFT(false)
            setByProfile(false)
            setByCollection(true)
            setActiveNFT(false)
            setActiveCollection(true)
            setActiveProfile(false)
            tabActive.current = "collection"
            return 0;
        }
        if(str === "profile"){
            setByNFT(false)
            setByProfile(true)
            setByCollection(false)
            setActiveNFT(false)
            setActiveCollection(false)
            setActiveProfile(true)
            tabActive.current = "profile"
        }
    }

    const handleConcatNfts = () =>{
        if(NFTData && NFTData.length > 0){
            setNewContent([...newContent, ...NFTData]);
        }
    }

    const handleConcatProfile = () =>{
        if(profileData && profileData.length > 0){
            setNewProfile([...newProfile,...profileData])
        }
    }

    const handleConcatCollection = () =>{
        if(data && data.length > 0){
            setNewCollection([...newCollection,...data])
        }
    }

    useEffect(()=>{
        handleConcatNfts();
    },[NFTData])

    useEffect(()=>{
        handleConcatProfile();
    },[profileData])

    useEffect(()=>{
        handleConcatCollection();
    },[data])

    const handleGetResultsWithOutFilter = ()=>{
        setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=SEARCH&key_val=news`)
    }

    const handleGetResultsWithFilter = ()=>{
        if(rangeBottom != undefined && rangeBottom != null && rangeTop != undefined && rangeTop != null){
            history(`/explore?limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&PRICE_RANGE_BOTTOM=${rangeBottom}&PRICE_RANGE_TOP=${rangeTop}`)
        }
        if(onSale && (String(onSale).toUpperCase() === "TRUE" )){
            history(`/explore?limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&&ON_SALE=${onSale}`)
        }
        if(liveAuction && (String(liveAuction).toUpperCase() === "TRUE" )){
            history(`/explore?limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&&LIVE_AUCTION=${liveAuction}`)
        }
        if(format && format === "IMAGE"|| format === "VIDEO" || format === "DOCUMENT" || format === "TRACK" ){
            history(`/explore?limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&format=${format}`)
        }
        if(key_name && key_val && String(key_name).toUpperCase() === "CATEGORY"){
            history(`/explore?limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=CATEGORY&key_val=${key_val}`)
        }
        if(key_name && key_val && String(key_name).toUpperCase() === "SEARCH"){
            history(`/explore?limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created&key_name=SEARCH&key_val=news`)
        }
        setUrlNFT(handleChoseLink());
    }

    const getNfts = () =>{
        if(!loading && !NFTLoading && !loadingProfileData ){
            if(sliceNFT < (countNfts.current - 20)){
                return 0;
            }
            countNfts.current = countNfts.current + limitNFTRef.current
            pageNftRef.current++;
            if(query.get("limit") && query.get("order") && query.get("key_name") && query.get("key_val") || query.get("key_val") != ''){
                handleGetResultsWithFilter();
                return 0;
            }
            handleGetResultsWithOutFilter();
        }
    }

    const incrementProfile = ()=>{
        setUrlProfile(`${process.env.REACT_APP_URL_API}/user?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitProfileRef.current}&page=${pageProfileRef.current}&order=created&role=5`)
    }

    const getProfile =  () =>{
        if(!loading && !NFTLoading && !loadingProfileData ){
            if((countProfile.current - 10) > sliceProfile){
                return 0;
            }
            countProfile.current = countProfile.current + limitProfileRef.current;
            pageProfileRef.current++;
            incrementProfile();
        }
    }

    const getMoreCollection = ()=>{
        setUrlProject(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&key_name=NEWS&key_val=NEWS&limit=${limitCollectionRef.current}&page=${pageCollectionRef.current}&order=created`)
    }
    const getCollection = () => {
        if(!loading && !NFTLoading && !loadingProfileData ){
            if(sliceCollection < (countCollection.current - 20)){
                return 0;
            }
            countCollection.current = countCollection.current + limitCollectionRef.current
            pageCollectionRef.current++;
            getMoreCollection();
        }
    }

    const infinityScroll = () => {
        if(tabActive.current == "nft" && newContent && sliceNFT <= newContent.length){
            getNfts();
            setSliceNFT(sliceNFT+10);
        }
        if(tabActive.current == "profile" && newProfile && sliceProfile  <= newProfile.length){
            getProfile();
            setSliceProfile(sliceProfile+10);
        }
        if(tabActive.current == "collection" && byCollection && sliceCollection <= newCollection.length){
            getCollection();
            setSliceCollection(sliceCollection+10)
        }
    }

    const handleInitSlug = () =>{
        if(query.get('SLUG') != null && query.get('SLUG') != '' && !NFTLoading && sliceNFT <= newContent.length){
            tabActive.current ='nft'
            setByNFT(true)
            setByProfile(false)
            setByCollection(false)
            setActiveNFT(true)
            setActiveCollection(false)
            setActiveProfile(false)
            handleResetLimitAndPAgeNFT();
            handleSlug();
            return null;
        } 
        if(query.get('SLUG') != null && query.get('SLUG') == '' && !NFTLoading){
            history('/explore')
        }
    }
    useEffect(() => {   
        handleInitSlug();
    },[query.get('SLUG'),NFTLoading])

    useEffect(()=>{ // !important show cards when finish request
        setFirstRequest(false);
        if(!firstRequest){
            changeFilter.current = false;
        }
    },[changeFilter,NFTData,NFTLoading]);

    useEffect(()=>{window.scrollTo(0,0);},[]);

    return (
        <>
            <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            > 
                <Typography 
                    variant="overline" 
                    display="block" 
                    gutterBottom 
                    component='h2'
                    sx={{
                        color:'#000', 
                        fontSize:{xs:'30px',sm:'30px',md:'34px',lg:'37px'},
                        textAlign:'center',
                        marginTop:'14px',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        background: 'linear-gradient(110.78deg, #361FD8 10%, #361FD8 10%, #A658D8 67.94%, #A658D8 85.34%, #A658D8 99.57%)',
                        backgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        textTransform: 'uppercase'
                    }}
                >
                    {t("gallery.title")}
                </Typography>
            </Box>

            <Box display='flex' justifyContent='flex-start' sx={{gap:'1rem', marginLeft:'1.5rem', fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>
                <ActiveTag active={activeNFT+''} onClick={()=>sortby('nft')}>{t('explore.nft_tab')}</ActiveTag>
                <ActiveTag active={activeCollection+''} onClick={()=>sortby('collection')}>{t('explore.collection_tab')}</ActiveTag>
                <ActiveTag active={activeProfile+''} onClick={()=>sortby('profile')}>{t('explore.profile_tab')}</ActiveTag>
            </Box>
            <Divider />

            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    '@media screen and (max-width: 750px)': {
                        display:'grid',
                        gridTemplateColumns:!openFilters?'repeat(1,1fr)':'repeat(auto-fit, minmax(330px, 1fr))',
                    },
                }}
            >
                { tabActive.current =='nft' && 
                    <Box
                        sx={{
                            p:'0px 10px'
                        }}
                    >
                        <SideBar 
                            urlProject={setUrlProject}
                            urlNFT={urlNFT}
                            setUrlNFT={setUrlNFT}
                            isNFT={byNFT} 
                            openFilters={openFilters} 
                            setOpenFilters={setOpenFilters} 
                            listNFT={listNFT} 
                            changeFilter={changeFilter}
                            setNewContent={setNewContent}
                            setSliceNFT={setSliceNFT}
                            requestIsLoad={NFTLoading}
                            countNfts={countNfts}
                            pageNftRef={pageNftRef}
                        />
                    </Box>
                }
                <Box display='flex' justifyContent='center' sx={{width:'100%', marginBottom:'1rem',mt:'30px'}}>
                    <Box
                        sx={{
                            width:'100%',
                            gridTemplateColumns:'repeat(1,1fr)',
                        }}
                    >
                        {byNFT && !NFTError && !firstRequest && !changeFilter.current &&  
                            <Box>
                                <NFTsCard  
                                    infinityScroll={infinityScroll} 
                                    content={newContent} 
                                    limit={sliceNFT} 
                                    openFilters={openFilters} 
                                    NFTLoading={NFTLoading} 
                                />
                            </Box>
                        }
                        {byCollection && data && 
                            <Box>
                                <CollectionCard   
                                    infinityScroll={infinityScroll} 
                                    content={newCollection} 
                                    limit={sliceCollection} 
                                    loadingCollection={loading} 
                                />
                            </Box>
                        }
                        {
                            byProfile && !errorProfileData && profileData && 
                            <Box>
                                <ProfileCard  
                                    infinityScroll={infinityScroll} 
                                    content={newProfile} 
                                    limit={sliceProfile} 
                                    loadingProfileData={loadingProfileData} 
                                />
                            </Box>
                        }                                    
                       
                        <ChoiceLoader
                            openFilters={openFilters}
                            byNFT={byNFT}
                            NFTLoading={NFTLoading}
                            NFTError={NFTError}
                            byCollection={byCollection}
                            loading={loading}
                            byProfile={byProfile}
                            loadingProfileData={loadingProfileData}
                        />
                        <ChoiceError
                            byNFT={byNFT}
                            NFTError={NFTError}
                            byCollection={byCollection}
                            error={error}
                            byProfile={byProfile}
                            errorProfileData={errorProfileData}
                        />
                    </Box>
                </Box>
            </Box>         
        </>
    )
}

export default Explore